/* eslint-disable */
import '@/../node_modules/btb-common_styles/src/scss/knacss.scss'
import '@/assets/index.scss'

import App from './App.vue'
import Keycloak from 'keycloak-js'
import { createApp } from 'vue'
import router from './router'
import { store } from './store'
import bus from './services/event-bus'

import { backendAuth } from './services/auth.handler'

// Packages NPM
import Vuelidate from '@vuelidate/core'
import ResizeSensor from 'resize-sensor'
import Toaster from '@meforma/vue-toaster'

window.ResizeSensor = ResizeSensor

let keycloakInitOptions = {
  url: process.env.VUE_APP_KEYCLOAK_BASE_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM_NAME,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID
}

let keycloak = new Keycloak(keycloakInitOptions)

keycloak
  .init({ onLoad: 'login-required', checkLoginIframe: false })
  .then(async function () {
    const app = createApp(App).use(store).use(router)
    // app config
    app.config.globalProperties.$keycloakLogout = keycloak.logout
    app.config.globalProperties.$bus = bus

    // packages init
    app.use(Vuelidate)
    app.use(Toaster, {
      position: 'top-right'
    })

    // store keycloak object
    store.dispatch('setKeycloakToken', keycloak.token)

    app.mount('#app')

    // Requete sur /me et gestion des comptes nouveau/ancien monde
    backendAuth({ logout: keycloak.logout })

    // Token Refresh
    setInterval(async () => {
      try {
        await keycloak.updateToken(600)
        store.dispatch('setKeycloakToken', keycloak.token)
      } catch (error) {
        console.error('Failed to refresh token', error)
      }
    }, 30000)
  })
  .catch((error) => {
    console.error('Authenticated Failed', error)
  })
